<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Teams from '@/services/Teams';
    import Games from '@/services/Games';
    import ModalAddMOTMVotes from "@/components/modals/games/ModalAddMOTMVotes";
    import ExcelJS from "exceljs";

    export default {
        components: { Layout, PageHeader, ModalAddMOTMVotes },
        page: {
            title: "Man of the match",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                isBusy: false,
                isBusy2: false,
                error: null,
                tableData: [],
                title: "Man of the match",
                items: [
                    {
                        text: "Fan Experience",
                    },
                    {
                        text: "Man of the match",
                    },
                ],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "votes",
                sortDesc: true,
                selectedMatch: '',
                matches: [],
                sortedMatches: [],
                homeTeamLogo: null,
                awayTeamLogo: null,
                homeTeamName: null,
                awayTeamName: null,
                homeTeamScore: null,
                awayTeamScore: null,
                date_formatted: null,
                isVoteOpen:'',
                fields: [
                    {
                        key: "shirt_number",
                        label: "Shirt Number",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Name",
                        sortable: true,
                    },
                    {
                        key: "votes",
                        label: "Votes",
                        sortable: true,
                    },
                ],

            };
        },
        async created() {
            this.matchesData()
        },
        computed: {
            hasDataForSelectedMatch() {
                // Check if there is data for the selected match (tableData is not empty)
                return this.tableData.length > 0;
            },
        },
        methods: {
            async getMatches(type){
                try {
                    //const response = await Teams.getFixtures(type)
                    if(process.env.VUE_APP_SPORT == 'hockey'){
                        const response = await Teams.getHockeyMatches(type);
                        if(response.data){
                            return response.data;
                        }
                    }else {
                      const response = await Teams.getFootballMatches(type);
                      if(response.data){
                          return response.data;
                      }
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

            async exportToExcel() {

                try {
                    alert('Generating report. Please wait...')
                    const response = await Games.exportMotmData(this.selectedMatch);

                    if (Array.isArray(response.data[1])) {
                        const ExcelJS = require('exceljs'); // Import ExcelJS
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet('ManOfTheMatch');

                        // Define column headers and their corresponding column letters
                        const columnHeaders = [
                            { key: 'customer_name', header: 'Customer Name', width: 40 },
                            { key: 'customer_email', header: 'Customer Email', width: 30 },
                            { key: 'player_name', header: 'Voted For', width: 30 },
                            { key: 'correct_vote', header: 'Correct Vote', width: 20 },
                        ];

                        // Add header row
                        worksheet.addRow(columnHeaders.map(column => column.header));
                        worksheet.getRow(1).font = { bold: true };

                        // Set column letters for each header
                        columnHeaders.forEach((column, index) => {
                            const cell = worksheet.getCell(String.fromCharCode(65 + index) + '1'); // A1, B1, C1, ...
                            cell.width = column.width;
                        });

                        // Add data rows from the API response
                        response.data[1].forEach(item => {
                            const rowData = columnHeaders.map(column => item[column.key]);
                            worksheet.addRow(rowData);
                        });

                        // Create a blob from the Excel workbook
                        const buffer = await workbook.xlsx.writeBuffer();

                        // Create a Blob object and initiate download
                        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'ManOfTheMatch.xlsx';
                        link.click();
                    }else {
                        alert('No data available for generating the report.');
                    }
                } catch (error) {
                    alert('An error occurred while fetching data from the API.'); // Display an error message
                }

            },

            async getCurrentMatches(data){
              try {
                const  response = await Games.getCurrentMatches(data)
                if(response.data){
                  return response.data;
                }
              } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
              }

            },

            async matchesData(){
                this.isBusy2 = true
                var upcomingMatches = await this.getMatches('UPCOMING');
                var pastMatches = await this.getMatches('PAST');

                var pastMatchTemp = [];
                var upcomingMatchTemp = [];
                this.isBusy2 = false
                if(upcomingMatches != null && upcomingMatches.matches.length > 0){
                   //upcomingMatchTemp = upcomingMatches.fixtures;
                   upcomingMatchTemp = upcomingMatches.matches;
                }
                if(pastMatches != null && pastMatches.matches.length > 0){
                   // pastMatchTemp = pastMatches.fixtures;
                  pastMatchTemp = pastMatches.matches;
                }
                this.matches = upcomingMatchTemp.concat(pastMatchTemp);
                this.sort_matches();
                // if (this.matches.length > 0) {
                //   this.selectedMatch = this.matches[0].match_id;
                //   await this.getMatchScorePrediction();
                // }
              if (this.matches.length > 0) {
                const currentMatch = this.matches.find(match => match.status === 'CURRENT');
                if (currentMatch) {
                  this.selectedMatch = currentMatch.match_id;
                } else {
                  this.selectedMatch = this.matches[0].match_id;
                }
                await this.getMatch();
              }
            },

            getMatch(){
                this.toggleBusy();
                this.homeTeamLogo = this.awayTeamLogo = this.homeTeamName = this.awayTeamName = this.homeTeamScore = this.awayTeamScore = this.date_formatted = null
                this.isVoteOpen = ''
                this.tableData = [];
                this.getMatchMotmData()

                Games.getMatchMotm(this.selectedMatch).then(response => {
                    if(response.data.data){
                        this.tableData = response.data.data;
                    }else{
                        this.tableData = [];
                    }
                    this.totalRows = this.tableData.length;

                    if(this.sortedMatches[this.selectedMatch]){
                        this.homeTeamLogo = this.sortedMatches[this.selectedMatch][0].home_team_logo;
                        this.awayTeamLogo = this.sortedMatches[this.selectedMatch][0].away_team_logo;
                        this.homeTeamName = this.sortedMatches[this.selectedMatch][0].home_team_name;
                        this.awayTeamName = this.sortedMatches[this.selectedMatch][0].away_team_name;
                        //  this.homeTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_home;
                        //  this.awayTeamScore = this.sortedMatches[this.selectedMatch][0].score.ft_score_away;
                        this.homeTeamScore = this.sortedMatches[this.selectedMatch][0].score_home;
                        this.awayTeamScore = this.sortedMatches[this.selectedMatch][0].score_away;
                        this.date_formatted = this.sortedMatches[this.selectedMatch][0].date_formatted;
                    }else{
                        this.homeTeamLogo = this.awayTeamLogo =  this.awayTeamLogo = this.homeTeamName = this.awayTeamName = '';
                    }

                    this.toggleBusy();
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.toggleBusy();
                })
            },

            sort_matches(){
                this.matches.sort((a, b) => {
                  return new Date(b.date_time) - new Date(a.date_time);
                });

                this.sortedMatches = this.matches.reduce(function (r, a) {
                    r[a.match_id] = r[a.match_id] || [];
                    r[a.match_id].push(a);
                    return r;
                }, Object.create(null));
            },

            getMatchMotmData(){
                Games.getMatchMotmData(this.selectedMatch)
                    .then(response => {
                        if(response.data.data){
                            this.isVoteOpen = response.data.data.status.isVoteOpen
                        }

                        //this.isVoteOpen = true
                    }).catch(error => {

                       // this.error = error.response.data.error ? error.response.data.error : "";
                    })
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            callModaladdMOTMVotes(){
                this.$bvModal.show("add_motm_votes");
            }

        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12 d-flex justify-content-center">
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="homeTeamLogo != null" :src="homeTeamLogo" width="50px" :alt="homeTeamName">
                                    <p class="font-size-16">{{homeTeamName}}</p>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <div class="form-row form-group mb-3" :class="isBusy2 ? 'placeholder-glow' :''">
                                        <label class="col-md-12 text-center col-form-label">Select Match</label>
                                        <select class="form-select" :class="isBusy2 ? 'placeholder' :''" v-model="selectedMatch" @change="getMatch()" id="selectMatch">
                                            <option v-for="match in matches" :key="match.match_id" :value="match.match_id">
                                                {{match.home_team_name}} - {{match.away_team_name}} / <span class="ms-2">{{match.date_time}}</span>
                                            </option>
                                        </select>
                                        <div class="row mt-4 w-100">
                                            <div class="col-md-12 text-center " v-if="homeTeamScore != null">
                                                <span class="me-5 text-strong font-size-18">{{homeTeamScore}}</span> : <span class="ms-5 text-strong font-size-18">{{awayTeamScore}}</span>
                                                <p class="mt-3"><span class="bg-primary text-white p-1">{{date_formatted}}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3 text-center">
                                    <img v-if="awayTeamLogo != null" :src="awayTeamLogo" width="50px" :alt="awayTeamName">
                                    <p class="font-size-16">{{awayTeamName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="mb-3 text-center">
                                    <h5>Man of the match</h5>
                                    <h6 v-if="isVoteOpen === true" class="text-success">Voting is open</h6>
                                    <h6 v-else-if="isVoteOpen === false" class="text-danger">Voting is closed</h6>
                                </div>
                            </div>
                        </div>

                      <div class="row mt-4 mb-5" v-if="tableData && tableData.length > 0">
                        <div class="col-md-12">
                          <div v-if="tableData[0].player_data && tableData[0].player_data.length > 0" class="mb-3 text-center">
                            <h5 class="mt-1">
                              {{ tableData[0].player_data[0].first_name + ' ' + tableData[0].player_data[0].last_name }}
                            </h5>
                            <h6 class="mt-1">{{ tableData[0].player_data[0].function }}</h6>
                            <img
                                width='200'
                                :src="tableData[0].player_data[0].img"
                                data-holder-rendered="true"
                                class="img-thumbnail rounded"
                            >
                            <h6 class="mt-1">Votes: {{ tableData[0].votes }}</h6>
                          </div>
                          <hr>
                        </div>
                      </div>

                        <div class="row mt-4">
                            <div class="col-md-6 mb-3">
                                <h5>Voting data</h5>
                            </div>
                            <div class="col-md-6 mb-3">
                                <b-button v-if="isVoteOpen"
                                          class="float-end"
                                          variant="primary"
                                          title="Add Votes"
                                          @click="callModaladdMOTMVotes()"
                                >Add Votes</b-button>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <!--{{match.match_id}}-->
                            <div class="col-md-6 mb-3">
                                <b-button
                                        v-if="selectedMatch && hasDataForSelectedMatch"
                                        style="margin-bottom: 10px"
                                        class="float-end"
                                        variant="success"
                                        title="Export to Excel"
                                        @click="exportToExcel()"
                                >Export to Excel</b-button>
                            </div>
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(name)="data">
                                      <span v-if="data.item.player_data && Array.isArray(data.item.player_data) && data.item.player_data.length > 0">
                                            {{ data.item.player_data[0].first_name + ' ' + data.item.player_data[0].last_name }}
                                      </span>
                                </template>

                                <template v-slot:cell(shirt_number)="data">
                                      <span v-if="data.item.player_data && Array.isArray(data.item.player_data) && data.item.player_data.length > 0">
                                          {{ data.item.player_data[0].shirt_number }}
                                      </span>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS -->
        <ModalAddMOTMVotes :match_id="selectedMatch" @onRefresh="getMatch()"></ModalAddMOTMVotes>
        <!-- END  MODALS -->
    </Layout>
</template>
