<script>
    import Games from '@/services/Games';
    import Fixtures from '@/services/Fixtures';
    import {required, numeric, minValue, maxValue} from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations'

    export default {
        components:{
            validationMessages
        },
        props: {
            match_id: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                home_team_short: process.env.VUE_APP_TENANT,
                csrf_token: localStorage.getItem('csrf_token'),
                votes: '',
                player_id: '',
                players: [],
                showLoader: false,
                submitted: false,
                showModal: false,
                tryingToEdit: false
            };
        },
        validations: {
            player_id:{
                required,
            },
            votes: {
                required,
                numeric,
                maxValue: maxValue(10000),
                minValue: minValue(1)
            }
        },
        methods: {

            async addMOTMvotes(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Games.saveMOTMvotes(this.match_id,{
                            team_id: this.home_team_short,
                            player_id: this.player_id,
                            votes: this.votes,
                            csrf_token: this.csrf_token
                        }).then((response) => {

                            if(response.data.success){
                                this.successmsg("Changes has been saved");
                                this.refreshData()
                                this.closeModal()
                            }else{
                                this.failedmsg(response.data.error)
                            }

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        })

                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            async getMatchDetails(){
                this.showLoader = true

                await Fixtures.getMatchExtended(this.match_id)
                    .then(response => {
                        if(response.data){
                            if(response.data.match){
                                if(response.data.match.home_team_short == this.home_team_short){
                                    this.players = response.data.lineups.home_team_starters.concat(response.data.lineups.home_team_subs)
                                }else if(response.data.match.away_team_short == this.home_team_short){
                                    this.players = response.data.lineups.away_team_starters.concat(response.data.lineups.away_team_subs)
                                }
                            }
                        }

                        this.isVoteOpen = true
                    }).catch(error => {

                    // this.error = error.response.data.error ? error.response.data.error : "";
                    }).finally(() => {
                        this.showLoader = false
                    })
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.tryingToEdit = false
                this.submitted = false
                this.player_id = ''
                this.votes = ''
            },
            setData(){
                this.getMatchDetails()
            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" @shown="setData" @hidden="resetProps" id="add_motm_votes" title="Add Votes For Player" title-class="font-18" centered>
        <form @submit.prevent="addMOTMvotes" v-if="!showLoader">

            <b-form-group label="Choose Player" label-for="player_id" class="mb-3">
                <select
                        class="form-select"
                        v-model="player_id"
                        id="player_id"
                        :class="{
                          'is-invalid': submitted && $v.player_id.$error,
                        }"
                >
                    <option value="">Select Player</option>
                    <option v-for="player in players" :key="player.player_id" :value="player.player_id">
                        {{player.player_name}} {{player.shirt_number}} ( {{player.player_position}} )
                    </option>
                </select>
                <validationMessages v-if="submitted" :fieldName="'Player'" :validationName="$v.player_id"></validationMessages>
            </b-form-group>

            <b-form-group label="Add Votes" label-for="votes" class="mb-3" >
                <b-form-input
                        v-model.trim="votes"
                        type="number"
                        min="1"
                        max="10000"
                        id="votes"
                        aria-describedby="votes-feedback"
                        :class="{
                          'is-invalid': submitted && $v.votes.$error,
                        }"
                >
                </b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Votes'" :validationName="$v.votes"></validationMessages>
            </b-form-group>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addMOTMvotes" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>